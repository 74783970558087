import AdminLogin from './AdminLogin/AdminLogin';
import './App.css';
import Homepage from './Homepage/Homepage';
import ProductDetails from './ProductDetails/ProductDetails';
import ProductList from './ProductList/ProductList';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/productlist" element={<ProductList />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/login" element={<AdminLogin />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
